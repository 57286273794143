import C from './constants.js';

const { INITIATIVE, ACTIVITIES } = C;

const defaults = {
    activities: {
        [ACTIVITIES.ACTIVITY_JOURNAL]: { indicatorMetricTargetOn: false },
        [ACTIVITIES.DEVELOPMENT]: { indicatorMetricTargetOn: false },
        [ACTIVITIES.DEVELOPMENT_PEP]: { indicatorMetricTargetOn: false },
        [ACTIVITIES.DISSEMINATION]: { indicatorMetricTargetOn: false },
        [ACTIVITIES.ENGAGEMENT]: { indicatorMetricTargetOn: false },
        [ACTIVITIES.EO_TEACHING]: { indicatorMetricTargetOn: false },
        [ACTIVITIES.EVALUATION]: { indicatorMetricTargetOn: false },
        [ACTIVITIES.INTERVENTION]: { indicatorMetricTargetOn: false },
        [ACTIVITIES.PHYSICAL]: { indicatorMetricTargetOn: false },
        [ACTIVITIES.SERVICE]: { indicatorMetricTargetOn: false },
        [ACTIVITIES.TEACHING]: { indicatorMetricTargetOn: false },
        [ACTIVITIES.TEACHING_CAPACITY]: { indicatorMetricTargetOn: false },
        [ACTIVITIES.TEAM_EDUCATION]: { indicatorMetricTargetOn: false },
        [ACTIVITIES.UPGRADE]: { indicatorMetricTargetOn: false },
    },
};

export default {
    [INITIATIVE.BASIC]: {
        name: INITIATIVE.BASIC,
        activities: defaults.activities,
    },
    [INITIATIVE.BII]: {
        name: INITIATIVE.BII,
        activities: defaults.activities,
    },
    [INITIATIVE.CULTURE]: {
        name: INITIATIVE.CULTURE,
        activities: defaults.activities,
    },
    [INITIATIVE.EDUCATION]: {
        name: INITIATIVE.EDUCATION,
        activities: defaults.activities,
    },
    [INITIATIVE.EDUCATION_NOT_NNF]: {
        name: INITIATIVE.EDUCATION_NOT_NNF,
        activities: defaults.activities,
    },
    [INITIATIVE.ENVIRONMENT]: {
        name: INITIATIVE.ENVIRONMENT,
        activities: defaults.activities,
    },
    [INITIATIVE.GLOBAL_HEALTH]: {
        name: INITIATIVE.GLOBAL_HEALTH,
        activities: defaults.activities,
    },
    [INITIATIVE.HUMANITARIAN]: {
        name: INITIATIVE.HUMANITARIAN,
        activities: defaults.activities,
    },
    [INITIATIVE.IMPACT_INVESTMENT]: {
        name: INITIATIVE.IMPACT_INVESTMENT,
        activities: defaults.activities,
    },
    [INITIATIVE.INNOVATION]: {
        name: INITIATIVE.INNOVATION,
        activities: defaults.activities,
    },
    [INITIATIVE.INTERMEDIATE]: {
        name: INITIATIVE.INTERMEDIATE,
        activities: defaults.activities,
    },
    [INITIATIVE.PEP]: {
        name: INITIATIVE.PEP,
        activities: {
            ...defaults.activities,
            [ACTIVITIES.DEVELOPMENT_PEP]: { indicatorMetricTargetOn: true },
            [ACTIVITIES.ENGAGEMENT]: { indicatorMetricTargetOn: true },
            [ACTIVITIES.SERVICE]: { indicatorMetricTargetOn: true },
            [ACTIVITIES.TEACHING_CAPACITY]: { indicatorMetricTargetOn: true },
            [ACTIVITIES.UPGRADE]: { indicatorMetricTargetOn: true },
        },
    },
    [INITIATIVE.CSLT]: {
        name: INITIATIVE.CSLT,
        activities: defaults.activities,
    },
    [INITIATIVE.RESEARCH_INFRASTRUCTURE]: {
        name: INITIATIVE.RESEARCH_INFRASTRUCTURE,
        activities: defaults.activities,
    },
    [INITIATIVE.SCIENCE]: {
        name: INITIATIVE.SCIENCE,
        activities: defaults.activities,
    },
    [INITIATIVE.SOCIAL]: {
        name: INITIATIVE.SOCIAL,
        activities: defaults.activities,
    },
};
