import WizardFlow from './wizardFlow';
import { CONTEXTS } from '../contexts';
import CONSTANTS from '../constants';

// ///////////////////
// WIZARD FLOWS
// ///////////////////

const flow = new WizardFlow({
    context: CONTEXTS.REPORT,
    pages: [
        'activities',
        'acknowledgementPolicy',
        'backgroundInformation',
        'collaborators',
        'evaluations',
        'employeesFunded',
        'endOfGrantReflections',
        'facilities',
        'funding',
        'goals',
        'openToUsers',
        'overview',
        'regulations',
        'reportSummary',
        'results',
        'risks',
    ],
});

// ///////////////////
// PAGES
// ///////////////////

const {
    activities,
    acknowledgementPolicy,
    backgroundInformation,
    collaborators,
    employeesFunded,
    endOfGrantReflections,
    evaluations,
    facilities,
    funding,
    goals,
    openToUsers,
    overview,
    regulations,
    reportSummary,
    results,
    risks,
} = flow.pages;

const { start, complete, done, sections } = flow.metaPages;

// ///////////////////
// COMMON STRUCTURES
// ///////////////////

const Status = [
    start(),
    flow.section('Overview', [overview]),
    flow.section('Inputs', [funding, collaborators]),
    flow.section('Learnings', [reportSummary], {
        hideSectionComplete: true,
    }),
    complete(),
    done,
];

const Learnings = {
    default: {
        Annual: [reportSummary],
        Final: [reportSummary, evaluations, endOfGrantReflections],
    },
    risks: {
        Annual: [risks],
        Final: [risks],
    },
    defaultWithRisks: {
        Annual: [reportSummary, risks],
        Final: [reportSummary, evaluations, endOfGrantReflections, risks],
    },
};

// ///////////////////
// METHODS
// ///////////////////

function reportSet(initiativeSections, learnings = 'default') {
    return {
        Status,
        Annual: [
            start(),
            sections(),
            ...initiativeSections,
            flow.section('Activities', [activities]),
            flow.section('Results', [results]),
            flow.section('Learnings', Learnings[learnings].Annual, {
                hideSectionInReportView: false,
                hideSectionComplete: true,
            }),
            complete(),
            done,
        ],
        Final: [
            start(),
            sections(),
            ...initiativeSections,
            flow.section('Activities', [activities]),
            flow.section('Results', [results]),
            flow.section('Learnings', Learnings[learnings].Final, {
                hideSectionInReportView: false,
                hideSectionComplete: true,
            }),
            complete(),
            done,
        ],
    };
}

// ///////////////////
// REPORT STRUCTURES
// ///////////////////

const reportStructures = {
    [CONSTANTS.INITIATIVE.SOCIAL]: reportSet([
        flow.section('Overview', [overview]),
        flow.section('Inputs', [funding, collaborators, employeesFunded]),
    ]),
    [CONSTANTS.INITIATIVE.HUMANITARIAN]: reportSet([
        flow.section('Overview', [overview]),
        flow.section('Inputs', [funding, collaborators, employeesFunded]),
    ]),
    [CONSTANTS.INITIATIVE.PEP]: reportSet([
        flow.section('Overview', [overview]),
        flow.section('Inputs', [funding, collaborators, employeesFunded]),
    ]),
    [CONSTANTS.INITIATIVE.CSLT]: reportSet([
        flow.section('Overview', [overview]),
        flow.section('Inputs', [funding, collaborators, employeesFunded]),
    ]),
    [CONSTANTS.INITIATIVE.EDUCATION]: reportSet([
        flow.section('Overview', [overview]),
        flow.section('Inputs', [funding, collaborators, employeesFunded]),
    ]),
    [CONSTANTS.INITIATIVE.EDUCATION_NOT_NNF]: reportSet([
        flow.section('Overview', [overview]),
        flow.section('Inputs', [funding, collaborators, employeesFunded]),
    ]),
    [CONSTANTS.INITIATIVE.ENVIRONMENT]: reportSet([
        flow.section('Overview', [overview]),
        flow.section('Inputs', [funding, collaborators, employeesFunded]),
    ]),
    [CONSTANTS.INITIATIVE.CULTURE]: reportSet([
        flow.section('Overview', [overview]),
        flow.section('Inputs', [funding, collaborators, employeesFunded]),
    ]),
    [CONSTANTS.INITIATIVE.IMPACT_INVESTMENT]: reportSet([
        flow.section('Overview', [overview]),
        flow.section('Inputs', [funding, collaborators, employeesFunded]),
    ]),
    [CONSTANTS.INITIATIVE.SCIENCE]: reportSet([
        flow.section('Overview', [overview]),
        flow.section('Inputs', [funding, collaborators, employeesFunded]),
    ]),
    [CONSTANTS.INITIATIVE.BII]: reportSet([
        flow.section('Overview', [overview]),
        flow.section('Inputs', [
            funding,
            collaborators,
            employeesFunded,
            facilities,
        ]),
    ]),
    [CONSTANTS.INITIATIVE.INNOVATION]: reportSet([
        flow.section('Overview', [overview]),
        flow.section('Inputs', [funding, collaborators, employeesFunded]),
    ]),
    [CONSTANTS.INITIATIVE.GLOBAL_HEALTH]: reportSet([
        flow.section('Overview', [overview]),
        flow.section('Inputs', [funding, collaborators, employeesFunded]),
    ]),
    [CONSTANTS.INITIATIVE.RESEARCH_INFRASTRUCTURE]: reportSet([
        flow.section('Overview', [
            overview,
            regulations,
            openToUsers,
            acknowledgementPolicy,
        ]),
        flow.section('Inputs', [funding, collaborators, employeesFunded]),
    ]),
    [CONSTANTS.INITIATIVE.BASIC]: reportSet(
        [
            flow.section('Overview', [overview, goals]),
            flow.section('Inputs', [funding, collaborators]),
        ],
        'defaultWithRisks'
    ),
    [CONSTANTS.INITIATIVE.INTERMEDIATE]: reportSet(
        [
            flow.section('Overview', [backgroundInformation, overview, goals]),
            flow.section('Inputs', [funding, collaborators, employeesFunded]),
        ],
        'defaultWithRisks'
    ),
};

// Export for permissions
const p = flow.pages;
export { p };

// Default export
export default reportStructures;
