import create from 'zustand';
import createVanilla from 'zustand/vanilla';
import _cloneDeep from 'lodash.clonedeep';
import { CONSTANTS } from 'utilities/configuration';

import {
    CONTEXTS,
    initiativeStructures,
    createStructures,
    reportStructures,
} from 'utilities/configuration';

// Auth shenaningans
import { grantPermissionBase } from 'utilities/hooks/usePermissions';

// Helper for getting next url
function _goToNextSection(sectionIndex, items) {
    // Next section
    const nextSection = items[sectionIndex + 1];

    // Return url from next section or the first child in items
    return nextSection ?? null
        ? nextSection.url
            ? nextSection.url
            : nextSection.items[0].url
        : null;
}

const wizardNavigationStore = createVanilla((set, get) => ({
    // Handles url change in bottom navigation
    async onUrlOrContextChange(baseUrl) {
        // Set current item
        const currentItem = await get().setCurrentItem(baseUrl);

        // Set sections based on location
        currentItem.parentItem
            ? get().toggleSection(currentItem.parentItem, true)
            : get().toggleSection(currentItem.item, true);

        // Set next item url
        get().setNextItemUrl(currentItem);

        // Reset submitHandler
        get().setCurrentSubmitHandler(null);
    },

    // Handles submit from wizard pages
    async handleSubmit() {
        const currentSubmitHandler = get().currentSubmitHandler;
        return currentSubmitHandler ? currentSubmitHandler() : true;
    },

    // Rebuilds wizard items
    buildItems({
        context,
        action,
        initiativeType = 'Advanced',
        reportType = null,
        permissionType = 'add',
        user = {},
    }) {
        if (user) {
            // Reset store
            get().reset();

            // Extract user data
            const {
                userAccountType,
                userInitiativeTeamRole,
                userAccountId,
            } = user;

            // Dictionary for remapping legacy initiative types
            const initativeStructureDictionary = {
                [CONSTANTS.INITIATIVE.SOCIAL]: CONSTANTS.INITIATIVE.SOCIAL,
                [CONSTANTS.INITIATIVE.HUMANITARIAN]:
                    CONSTANTS.INITIATIVE.HUMANITARIAN,
                [CONSTANTS.INITIATIVE.PEP]: CONSTANTS.INITIATIVE.PEP,
                [CONSTANTS.INITIATIVE.CSLT]: CONSTANTS.INITIATIVE.CSLT,
                [CONSTANTS.INITIATIVE.EDUCATION]:
                    CONSTANTS.INITIATIVE.EDUCATION,
                [CONSTANTS.INITIATIVE.EDUCATION_NOT_NNF]:
                    CONSTANTS.INITIATIVE.EDUCATION_NOT_NNF,
                [CONSTANTS.INITIATIVE.ENVIRONMENT]:
                    CONSTANTS.INITIATIVE.ENVIRONMENT,
                [CONSTANTS.INITIATIVE.CULTURE]: CONSTANTS.INITIATIVE.CULTURE,
                [CONSTANTS.INITIATIVE.IMPACT_INVESTMENT]:
                    CONSTANTS.INITIATIVE.IMPACT_INVESTMENT,
                [CONSTANTS.INITIATIVE.SCIENCE]: CONSTANTS.INITIATIVE.SCIENCE,
                [CONSTANTS.INITIATIVE.BII]: CONSTANTS.INITIATIVE.BII,
                [CONSTANTS.INITIATIVE.INNOVATION]:
                    CONSTANTS.INITIATIVE.INNOVATION,
                [CONSTANTS.INITIATIVE.GLOBAL_HEALTH]:
                    CONSTANTS.INITIATIVE.GLOBAL_HEALTH,
                [CONSTANTS.INITIATIVE.RESEARCH_INFRASTRUCTURE]:
                    CONSTANTS.INITIATIVE.RESEARCH_INFRASTRUCTURE,
                [CONSTANTS.INITIATIVE.BASIC]: CONSTANTS.INITIATIVE.BASIC,
                [CONSTANTS.INITIATIVE.INTERMEDIATE]:
                    CONSTANTS.INITIATIVE.INTERMEDIATE,

                // Legacy
                Advanced: CONSTANTS.INITIATIVE.SOCIAL,
                Default: CONSTANTS.INITIATIVE.SOCIAL,
                Reporting: CONSTANTS.INITIATIVE.SOCIAL,
            };

            // Get real type from dictionary
            const initativeStructureType =
                initativeStructureDictionary[initiativeType];

            // Report Type dictionary
            const reportTypeDictionary = {
                Status: 'Status',
                Annual: 'Annual',
                Final: 'Final',
                Standard: 'Annual',
                Extended: 'Final',
            };

            // Get structure based on context before authentication check
            let navigationStructure = [];
            switch (context) {
                case CONTEXTS.CREATE:
                    navigationStructure =
                        _cloneDeep(createStructures)?.Default ?? [];
                    break;
                case CONTEXTS.INITIATIVE:
                    navigationStructure =
                        _cloneDeep(initiativeStructures)?.[
                            initativeStructureType
                        ] ?? [];
                    break;
                case CONTEXTS.REPORT:
                    navigationStructure =
                        _cloneDeep(reportStructures)?.[
                            initativeStructureType
                        ]?.[reportTypeDictionary[reportType]] ?? [];
                    break;
            }

            // Loop through navigation structure
            let authenticatedNavigationStructure = [];
            for (let section of [...navigationStructure]) {
                // Check all the section items
                if (section?.items?.length > 0) {
                    // New array for items that are authenticated
                    let authenticatedItems = [];
                    for (const item of section.items) {
                        // Push item to new array if permission is granted
                        if (
                            item?.metaItem ||
                            grantPermissionBase(
                                item?.permissions[permissionType],
                                userAccountType,
                                userInitiativeTeamRole,
                                userAccountId
                            )
                        ) {
                            authenticatedItems.push(item);
                        }
                    }

                    // If there are any authenticated items except metaItems, show them
                    if (
                        authenticatedItems.filter(x => !x.metaItem).length > 0
                    ) {
                        // Overwrite section items with new items
                        section.items = authenticatedItems;
                    }
                }
                // Always add to authenticated navigation struture
                // Either with non modified section or authenticated section items
                authenticatedNavigationStructure = [
                    ...authenticatedNavigationStructure,
                    section,
                ];
            }

            // Update state
            set(state => {
                state.items = authenticatedNavigationStructure;
            });
        }
    },

    // Sets the current item and parent item if any
    async setCurrentItem(url) {
        const items = get().items;
        let item;
        let itemIndex;
        let parentItem = null;
        let parentItemIndex = null;

        // Search parent items
        item = items.find(parentItem => parentItem.baseUrl === url);
        itemIndex = items.findIndex(parentItem => parentItem.baseUrl === url);

        // If no top level check for nested
        if (!item) {
            // Check for nested
            parentItem = items.find(parentItem =>
                parentItem.items?.some(childItem => childItem.baseUrl === url)
            );
            parentItemIndex = items.findIndex(parentItem =>
                parentItem.items?.some(childItem => childItem.baseUrl === url)
            );

            // Look through children
            item = parentItem?.items?.find(
                childItem => childItem.baseUrl === url
            );
            itemIndex = parentItem?.items?.findIndex(
                childItem => childItem.baseUrl === url
            );
        }

        const currentItem = {
            item,
            itemIndex,
            parentItem,
            parentItemIndex,
        };

        // Update state
        set(() => ({
            currentItem,
        }));

        // Return
        return currentItem;
    },

    // Toggles section in navigation
    toggleSection(section) {
        set(() => ({ openSection: section?.id }));
    },

    // Sets the url of the next item
    setNextItemUrl(currentItem) {
        const { itemIndex, parentItem, parentItemIndex } = currentItem;
        const items = get().items;

        // Wait for iiiit
        let nextItemUrl;

        // Child next url
        if (parentItem) {
            // Is the current item the last item?
            nextItemUrl =
                itemIndex + 1 === parentItem.items?.length ?? 0
                    ? _goToNextSection(parentItemIndex, items)
                    : parentItem.items[itemIndex + 1].url;
        }

        // Parent/section level
        else {
            nextItemUrl = _goToNextSection(itemIndex, items);
        }

        // Update state
        set(() => ({
            nextItemUrl,
        }));
    },

    // Set the submit handler for the current view
    setCurrentSubmitHandler(currentSubmitHandler) {
        set(() => ({
            currentSubmitHandler,
        }));
    },

    // Returns sections from current navigation structure
    getSections() {
        return get().items.filter(item => item.section);
    },

    items: [],
    openSection: null,
    currentItem: null,
    currentSubmitHandler: null,
    nextItemUrl: null,

    reset() {
        set(() => ({
            items: [],
            openSection: null,
            currentItem: null,
            currentSubmitHandler: null,
            nextItemUrl: null,
        }));
    },
}));

const useWizardNavigationStore = create(wizardNavigationStore);

export { useWizardNavigationStore, wizardNavigationStore };
