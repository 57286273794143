// React
import React from 'react';

// Utilities
import { useLabels, useContext } from 'utilities/hooks';

// Packages

// Components
import SummaryContentBox from 'components/_report/templates/report_5_0_pdf/summaryContentBox';
import SectionContainer from 'components/_report/templates/report_5_0_pdf/sectionContainer';
import Collection from 'components/_report/templates/report_5_0_pdf/collection';

import Evaluation from 'components/_cardContents/evaluation';

const SectionLearningsComponent = ({ utilities, items }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { REPORT_ID } = useContext();

    // ///////////////////
    // DATA
    // ///////////////////

    const report = utilities.reports.get(REPORT_ID);

    // Based on baseUrl in config
    const sectionComponents = {
        'end-of-grant-reflections'(props) {
            return <EndOfGrantReflectionsSection {...props} />;
        },
        evaluations(props) {
            return <EvaluationsSection {...props} />;
        },
        'report-summary'(props) {
            return <ReportSummarySection {...props} />;
        },
        risks(props) {
            return <RisksSection {...props} />;
        },
    };

    // ///////////////////
    // RENDER
    // ///////////////////

    return items.map(navigationItem => (
        <div key={navigationItem?.baseUrl}>
            {sectionComponents[navigationItem.baseUrl] &&
                sectionComponents[navigationItem.baseUrl]({
                    report,
                    navigationItem,
                    utilities,
                })}
        </div>
    ));
};

const EndOfGrantReflectionsSection = ({ report, navigationItem }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { object } = useLabels();

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <SectionContainer
            {...{
                item: navigationItem,
            }}>
            <SummaryContentBox
                {...{
                    title: object.label(
                        'Initiative_Report__c.Project_Purpose__c'
                    ),
                    content: report?.Project_Purpose__c,
                    html: true,
                }}
            />
            <SummaryContentBox
                {...{
                    title: object.label(
                        'Initiative_Report__c.Progress_Towards_Grant_Area_Themes__c'
                    ),
                    content: report?.Progress_Towards_Grant_Area_Themes__c,
                    html: true,
                }}
            />
            <SummaryContentBox
                {...{
                    title: object.label(
                        'Initiative_Report__c.Important_Results__c'
                    ),
                    content: report?.Important_Results__c,
                    html: true,
                }}
            />
        </SectionContainer>
    );
};

const EvaluationsSection = ({ report, utilities, navigationItem }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label } = useLabels();

    // ///////////////////
    // DATA
    // ///////////////////

    // Get items
    const items = utilities.reportDetails.getTypeEvaluationFromReportId(
        report.Id
    );

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <SectionContainer
            {...{
                item: navigationItem,
            }}>
            <Collection
                {...{
                    collection: {
                        items,
                        emptyLabel: label('EmptyStateWizardPageEvaluations'),
                    },
                    card: {
                        preTitle() {
                            return label('CardTypeEvaluation');
                        },
                        title(item) {
                            return item?.Things_To_Do__c;
                        },
                        components(item) {
                            return {
                                cardContent: <Evaluation {...{ item }} />,
                            };
                        },
                    },
                }}
            />
        </SectionContainer>
    );
};

const ReportSummarySection = ({ report, navigationItem }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { object, getValueLabel } = useLabels();

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <SectionContainer
            {...{
                item: navigationItem,
            }}>
            <SummaryContentBox
                {...{
                    title: object.label(
                        'Initiative_Report__c.Initiative_Status__c'
                    ),
                    content: getValueLabel(
                        'Initiative_Report__c.Initiative_Status__c',
                        report?.Initiative_Status__c
                    ),
                }}
            />
            <SummaryContentBox
                {...{
                    title: object.label(
                        'Initiative_Report__c.Summary_Of_Activities__c'
                    ),
                    content: report?.Summary_Of_Activities__c,
                    html: true,
                }}
            />
            <SummaryContentBox
                {...{
                    title: object.label(
                        'Initiative_Report__c.Summary_Of_Challenges_And_Learnings__c'
                    ),
                    content: report?.Summary_Of_Challenges_And_Learnings__c,
                    html: true,
                }}
            />
        </SectionContainer>
    );
};

const RisksSection = ({ report, navigationItem }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { object } = useLabels();

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <SectionContainer
            {...{
                item: navigationItem,
            }}>
            <SummaryContentBox
                {...{
                    title: object.label(
                        'Initiative_Report__c.Summary_Of_Initiative_Risks__c'
                    ),
                    content: report?.Summary_Of_Initiative_Risks__c,
                }}
            />
        </SectionContainer>
    );
};

SectionLearningsComponent.propTypes = {};

SectionLearningsComponent.defaultProps = {};

export default SectionLearningsComponent;
