const CONSTANTS = {
    ACCOUNT: {
        FOUNDATION: 'Foundation',
        GRANTEE: 'Grantee',
    },
    ACTIVITIES: {
        ACTIVITY_JOURNAL: 'Journal publication',

        ENGAGEMENT: 'Engagement',
        EO_TEACHING: 'EO teaching',
        TEACHING: 'Teaching',
        TEACHING_CAPACITY: 'Teaching capacity',
        DEVELOPMENT: 'Development of materials',
        DEVELOPMENT_PEP: 'Development of materials PEP',
        TEAM_EDUCATION: 'Team education',
        PHYSICAL: 'Physical',
        INTERVENTION: 'Intervention',
        UPGRADE: 'Upgrade facilities',
        SERVICE: 'Service delivery',

        // LEGACY
        DISSEMINATION: 'Dissemination',
        EVALUATION: 'Evaluation',
    },
    ACTIVITY_GOALS: {},
    ACTIVITY_SUCCESS_METRICS: {
        INDICATOR_CUSTOM: 'Custom',
        INDICATOR_PREDEFINED: 'People',
        INDICATOR_GENDER_OTHER: 'Other',

        CUSTOM: 'Custom',
        PEOPLE: 'People',
        FINANCIAL: 'Financial',
        NAME_PRIMARY: 'Metric_PrimaryAudience',
        NAME_SECONDARY: 'Metric_SecondaryAudience',
        NAME_ADDITIONAL: 'Metric_Additional',

        PRIORITY_PRIMARY: 'Primary',
        PRIORITY_SECONDARY: 'Secondary',
        PRIORITY_ADDITIONAL: 'Additional',
    },
    COLLABORATORS: {
        ADDITIONAL_COLLABORATORS: 'Additional collaborator',
        FINANCIAL_RECIPIENTS: 'Financial recipient',
    },
    CONTENTS: {
        PICTURE: 'Picture',
        VIDEO: 'Video',
        DOCUMENT: 'Document',
    },
    EMPLOYEES_FUNDED: {
        GENDER_OTHER: 'Other',
        GENDER_MALE: 'Male',
        GENDER_FEMALE: 'Female',
    },
    FUNDERS: {
        LEAD_FUNDER: 'Lead funder',
        ADDITIONAL_FUNDER: 'Additional funder',
    },
    FUNDING_RECIPIENTS: {
        MAIN_APPLICANTS: 'Main applicant',
        CO_APPLICANTS: 'Co applicant',
    },
    GOALS: {
        GOAL_CUSTOM: 'Custom',
        GOAL_PREDEFINED: 'Foundation',
    },
    INITIATIVE: {
        BASIC: 'Basic',
        BII: 'Bio innovation institute',
        CULTURE: 'Culture',
        EDUCATION: 'Education and outreach',
        EDUCATION_NOT_NNF: 'Education and outreach not NNF',
        ENVIRONMENT: 'Environment and climate',
        GLOBAL_HEALTH: 'Global health',
        HUMANITARIAN: 'Humanitarian',
        IMPACT_INVESTMENT: 'Impact investment',
        INNOVATION: 'Innovation',
        INTERMEDIATE: 'Intermediate',
        PEP: 'PEP Humanitarian',
        RESEARCH_INFRASTRUCTURE: 'Research infrastructure',
        SCIENCE: 'Science',
        SOCIAL: 'Social',
        CSLT: 'CSLT',
    },
    MILESTONES: {
        PLANNED: 'Planned',
        IN_PROGRESS: 'In progress',
        IN_REVIEW: 'In review',
        COMPLETE: 'Complete',
        TYPE_RESULT: 'Result',
    },
    REPORT_DETAIL_ENTRIES: {},
    REPORT_DETAIL_GOALS: {},
    REPORT_DETAILS: {
        ACHIEVEMENT: 'Achievement',
        LEARNING: 'Learning',
        CHALLENGE: 'Challenge',
        OUTCOME: 'Outcome',
        DOCUMENT: 'Document',
        FUNDER_OVERVIEW: 'Funder Overview',
        FUNDING: 'Funding',
        COLLABORATOR_OVERVIEW: 'Collaborator Overview',
        EMPLOYEES_FUNDED_OVERVIEW: 'Employees Funded Overview',
        ACTIVITY_OVERVIEW: 'Activity Overview',
        EVALUATION: 'Evaluation',
        INFLUENCE_ON_POLICY: 'Influence On Policy',
        UPDATE_OVERVIEW: 'Update Overview',
        RESULTS: 'Results',
        GOAL: 'Goal',
        REGULATION: 'Regulation',
        MILESTONE: 'Milestone',
        MILESTONE_KPI: 'Milestone KPI',
        MILESTONE_ACTIVITY: 'Milestone Activity',
        LOCATION: 'Location',
        LOGBOOK_UPDATE: 'Update', // Update overview?
    },
    REPORTS: {
        REPORT_NOT_STARTED: 'Not started',
        REPORT_IN_PROGRESS: 'In progress',
        REPORT_IN_REVIEW: 'In review',
        REPORT_PUBLISHED: 'Published',
    },
    RESULTS: {
        AWARD: 'Award',
        COMPANY: 'Company',
        CUSTOM_OUTCOME: 'Custom Outcome',
        CUSTOM_OUTPUT: 'Custom Output',
        DISSEMINATION: 'Dissemination',
        INFLUENCE_ON_POLICY: 'Influence On Policy',
        INNOVATION: 'Innovation',
        INTELLECTUAL_PROPERTY: 'Intellectual property',
        INVENTION_DISCLOSURE: 'Invention disclosure',
        KNOWLEDGE: 'Knowledge',
        PATENTS: 'Patents',
        PRODUCTS_AND_INVENTIONS: 'Products and inventions',
        RESEARCH: 'Research',
    },
    TAGS: {
        COLLECTION: 'Collection',
        GOAL: 'Goal',
        FUNDER: 'Funder',
        FUNDING: 'Funding',
        ACTIVITY: 'Activity',
        INITIATIVE: 'Initiative',
        DISSEMINATION_ACTIVITY: 'Dissemination Activity',
        INTERVENTION_ACTIVITY: 'General Activity',
        PHYSICAL_ACTIVITY: 'Physical Activity',
        TEAM_EDUCATION_ACTIVITY: 'Team Education Activity',
        TEACHING_ACTIVITY: 'Teaching Activity',
        TEACHING_ACTIVITY_CAPACITY: 'Teaching Capacity Activity',
        EO_TEACHING_ACTIVITY: 'EO Teaching Activity',
        ENGAGEMENT_ACTIVITY: 'Engagement Activity',
        DEVELOPMENT_ACTIVITY: 'Development Of Materials Activity',
        DEVELOPMENT_ACTIVITY_PEP: 'Development Of Materials Activity PEP',
        UPGRADE_ACTIVITY: 'Upgrade Facilities Activity',
        SERVICE_ACTIVITY: 'Service Delivery Activity',
        EMPLOYEE_FUNDED: 'Employee Funded',
        EVALUATION: 'Evaluation',
        SUCCESS_METRIC: 'Success Metric',
        SUCCESS_METRIC_PEOPLE: 'Success Metric People',
        SUCCESS_METRIC_CUSTOM: 'Success Metric Custom',
        SUCCESS_METRIC_FINANCIAL: 'Success Metric Financial',
    },
    UPDATE_CONTENTS: {
        PICTURE: 'Picture',
        VIDEO: 'Video',
        DOCUMENT: 'Document',
    },
    UPDATES: {
        LOGBOOK_TYPE_METRICS: 'Success Metric Update',
        LOGBOOK_TYPE_UPDATE: 'Update',
    },
    CUSTOM: {
        NO_REFLECTIONS: '__NO__REFLECTIONS__',
    },
    // Legacy reports
    1.1: {
        ACTIVITIES: {
            ACTIVITY_INTERVENTION: 'Intervention',
            ACTIVITY_DISSEMINATION: 'Dissemination',
        },
        COLLABORATORS: {
            MAIN_COLLABORATOR: 'Main applicant',
            ADDITIONAL_COLLABORATORS: 'Additional collaborator',
            APPLICANTS_CREATE: ['Co applicant'],
        },
    },
};

export default CONSTANTS;
